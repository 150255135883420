.heading-16 {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0%;
	padding: 0px 0px 10px;
	border-radius: 20px;
	background-color: transparent;
	color: #005e9e;
	font-size: 3.125rem;
	line-height: 1.1;
	font-weight: 700;
	text-transform: none;
}

.content-boxes a,
.main-content-inner a,
.textlink-middleout {
	border-bottom: 1px solid #005e9e;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #005e9e;
	text-decoration: none;
}

.content-boxes a:hover,
.main-content-inner a:hover,
.textlink-middleout:hover {
	border-bottom-color: transparent;
	box-shadow: none;
	color: #005e9e;
}

h1 {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0%;
	padding: 0px 0px 10px;
	border-radius: 20px;
	background-color: transparent;
	color: #005e9e;
	font-weight: 700;
	text-transform: none;
	font-size: 2.375rem;
	line-height: 1.2;
}

h2,
.heading-9 {
	font-size: 2rem;
	line-height: 1.2;
	color: #005e9e;
}

h3,
.heading-20,
.heading-10 {
	font-size: 1.5rem;
	line-height: 1.2;
	color: #005e9e;
}

h4,
.heading-17 {
	font-size: 1.125rem;
	line-height: 1.5rem;
	color: #005e9e;
}

h5,
.heading-18 {
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: #005e9e;
}

h6,
.heading-19 {
	font-size: 0.75rem;
	line-height: 1.125rem;
	color: #005e9e;
}

.main-content-inner h1 a,
.main-content-inner h2 a,
.main-content-inner h3 a,
.main-content-inner h4 a,
.main-content-inner h5 a,
.main-content-inner h6 a {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #4a852c;
	border: 0;
	text-decoration: underline;
}

.main-content-inner h1 a:hover,
.main-content-inner h2 a:hover,
.main-content-inner h3 a:hover,
.main-content-inner h4 a:hover,
.main-content-inner h5 a:hover,
.main-content-inner h6 a:hover {
	color: #b8b8b8;
}

.main-content-inner p,
.main-content-inner div,
.paragraph-13 {
	font-size: 17px;
	line-height: 22px;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.primary,
.secondary,
.tertiary,
a.primary,
a.secondary,
a.tertiary,
.button-5 {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-style: solid;
	border-width: 2px;
	border-color: #6bb745;
	border-radius: 50px;
	background-color: #6bb745;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Muli, sans-serif;
	color: #000;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
a.primary:hover,
a.secondary:hover,
a.tertiary:hover,
.button-5:hover {
	border-style: solid;
	border-width: 2px;
	border-color: #ffd100;
	background-color: #ffd100;
	color: #005e9e;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.secondary,
a.secondary,
.button-5.secondary {
	border-style: solid;
	border-color: #ffd100;
	background-color: #ffd100;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #005e9e;
	text-align: center;
	cursor: pointer;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.secondary:hover,
a.secondary:hover,
.button-5.secondary:hover {
	border-color: #005e9e;
	background-color: #fff;
	background-image: none;
	opacity: 1;
	color: #005e9e;
}

.tertiary,
a.tertiary,
.button-5.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 2px solid transparent;
	background-color: #005e9e;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.tertiary:hover,
a.tertiary:hover,
.button-5.tertiary:hover {
	background-color: #f1f1f1;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

ul,
ol,
.list {
	font-size: 17px;
	line-height: 22px;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

hr,
.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
	border: 0;
}

#printHeader,
#printFooter {
	display: none;
}

/* Webflow start */
.heading-2-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #4a852c;
	font-size: 32px;
	line-height: 25px;
	text-decoration: underline;
	cursor: pointer;
}

.heading-2-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #b8b8b8;
	line-height: 6px;
}

.slider {
	height: 600px;
}

.slide {
	background-image: url('/20200317134300/assets/images/27656263908_fc1a4f8d7f_k.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.static-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 450px;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('/20200317134300/assets/images/44107268054_3878f85932_k.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/20200317134300/assets/images/44107268054_3878f85932_k.jpg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, cover;
	background-repeat: repeat, repeat-x;
	background-attachment: scroll, fixed;
}

.main-nav-link,
.nav-button {
	width: auto;
	height: auto;
	margin-top: 0px;
	margin-right: 10px;
	margin-left: 10px;
	padding: 10px;
	border-bottom: 4px solid #005e9e;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Muli, sans-serif;
	color: #005e9e;
	font-size: 14px;
	font-weight: 800;
	text-transform: capitalize;
}

.main-nav-link:hover,
.nav-button:hover {
	border-bottom-color: #6bb745;
	color: #005e9e;
}

.nav-button.w--current {
	border-bottom-color: #6bb745;
	color: #005e9e;
}

.slide-2 {
	background-image: url('/20200317134300/assets/images/48124317413_aa69656390_k.jpg');
	background-position: 100% 15%;
	background-size: 120%;
	background-repeat: no-repeat;
}

.slider-section {
	margin-top: 91px;
}

.slider-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 600px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: url('/20200317134300/assets/images/Slider-Graphic-V2.svg');
	background-position: 120% -16%;
	background-size: 87%;
	background-repeat: no-repeat;
}

.content-boxes {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	max-height: none;
	padding: 100px 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	background-color: #fff;
	background-image: url('/20200317134300/assets/images/Soy-Texture.svg');
	background-position: 50% 100%;
	background-size: auto 170%;
	background-repeat: repeat-x;
}

.blue-title {
	display: block;
	margin: 10px 20px 5px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-family: Muli, sans-serif;
	color: #005e9e;
	font-size: 22px;
	line-height: 33px;
	font-weight: 800;
	text-align: left;
}

.cb-description {
	width: auto;
	margin-right: 20px;
	margin-bottom: 10px;
	margin-left: 20px;
	font-family: Muli, sans-serif;
	color: #000;
	font-size: 15px;
	line-height: 22px;
	font-weight: 300;
	text-align: left;
}

a.cb-button,
.cb-button {
	margin-right: 40px;
	margin-bottom: 20px;
	margin-left: 20px;
	border-radius: 20px;
	background-color: #6bb745;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Muli, sans-serif;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	border: 0;
}

a.cb-button:hover,
.cb-button:hover {
	background-color: #ffd100;
	color: #005e9e;
}

.video-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: auto;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding: 0px 0%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	background-image: url('/20200317134300/assets/images/Soy-Texture.svg');
	background-position: 50% 100%;
	background-size: auto 170%;
	background-repeat: repeat-x;
}

.slider-callout {
	width: 42%;
	margin-right: 50px;
	margin-bottom: 20px;
	font-family: Muli, sans-serif;
	color: #fff;
	font-size: 40px;
	line-height: 45px;
	font-weight: 900;
	text-align: right;
	text-transform: none;
	margin-top: 20px;
}

.cb-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 28%;
	height: auto;
	margin: 0px 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: #eee;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.cb-container:hover {
	box-shadow: 3px 3px 18px -9px #000;
	-webkit-transform: scale(1.06);
	-ms-transform: scale(1.06);
	transform: scale(1.06);
}

.latest-news-img {
	height: 55%;
	min-height: 55%;
	width: 100%;
}

.projects-img {
	height: 55%;
	min-height: 55%;
	width: 100%;
}

.biodiesel-in-iowa-img {
	overflow: hidden;
	height: 55%;
	min-height: 55%;
	width: 100%;
}

.static-callout {
	margin-bottom: 20px;
	font-family: Muli, sans-serif;
	color: #fff;
	font-size: 50px;
	font-weight: 900;
}

.callout-text {
	width: auto;
	font-family: Muli, sans-serif;
	color: #fff;
	font-size: 25px;
	font-weight: 300;
	text-align: center;
}

.footer-section {
	height: auto;
	margin-top: 0px;
	padding: 40px 160px 40px 195px;
	background-color: #005e9e;
}

.paragraph-5 {
	font-family: Muli, sans-serif;
	color: #fff;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	clear: both;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.footer-social-block {
	width: 50%;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding-right: 1rem;
}

.footer-flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.75rem;
	padding-bottom: 1.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid #ffd100;
}

.footer-contact-wrap {
	display: block;
	width: 50%;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 1rem;
}

.footer-link {
	font-family: Muli, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 700;
	text-transform: uppercase;
	transition: all 350ms ease;
}

.footer-link:hover {
	box-shadow: none;
	color: #a3daff;
}

.footer-link:focus {
	box-shadow: 0 1px 0 0 #163a59;
}

.footer-link.signoff-link {
	font-size: 0.8rem;
}

.footer-column-title {
	width: 100.15%;
	margin-top: 0.5em;
	margin-bottom: 0.375em;
	padding-top: 0.125em;
	font-family: Muli, sans-serif;
	color: #fff;
	font-size: 1.375em;
	line-height: 1.25em;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.footer-column-title.footer-notifications-title {
	color: #fff;
	font-size: 1.4125em;
	line-height: 1.25em;
}

.footer-quick-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	font-family: Muli, sans-serif;
	color: #fff;
	line-height: 1.4;
	font-weight: 400;
	text-transform: capitalize;
	transition: all 350ms ease;
}

.footer-quick-link:hover {
	border-bottom-color: #797676;
	color: #a3daff;
}

.footer-quick-link.selected,
.footer-quick-link.w--current {
	color: #a3daff;
}

.footer-social-icons-wrap {
	margin-bottom: 1em;
	margin-left: 0px;
	padding-left: 0px;
}

.footer-phone-span {
	color: #fff;
	text-decoration: none;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0rem;
	font-family: Muli, sans-serif;
	color: #fff;
}

.copyright-text h1 {
	display: inline;
	margin: 0;
	padding: 0;
	font-family: Muli, sans-serif;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	font-weight: normal;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.column.footer-signoff-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.column.footer-signoff-column.right-signoff-column {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-top: 0.125em;
	padding-right: 0.45rem;
	padding-left: 0.45rem;
}

.grip-logo-image-2 {
	width: 10rem;
	min-width: 150px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.social-icons {
	width: 30px;
	margin-right: 10px;
	padding: 5px;
	background-color: rgba(0, 0, 0, 0.2);
}

.social-icons.twitter {
	padding: 6px;
}

.social-icons:hover {
	transition: all 350ms ease;
	background-color: #0d7bc5;
}

.twitter-feed {
	width: 100%;
	height: auto;
	margin-right: 0px;
	margin-left: 0px;
	text-align: center;
	padding-right: 0px;
}

.video-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 100%;
	margin-left: 20px;
	padding-right: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.video-div-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 43%;
	height: 100%;
	max-width: none;
	margin: 100px 60px 100px 0px;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.video-place-holder {
	width: auto;
	height: auto;
	margin-right: 0px;
}

.slide-nav {
	display: block;
}

.video-title {
	width: 100%;
	margin: 0px 140px 10px 0px;
	font-family: Muli, sans-serif;
	color: #005e9e;
	font-size: 30px;
	line-height: 33px;
	font-weight: 900;
	text-align: left;
}

.video-caption {
	width: 100%;
	margin: 10px 0px 40px;
	font-family: Muli, sans-serif;
	color: #000;
	font-size: 20px;
	line-height: 26px;
	font-weight: 300;
	text-align: left;
}

.video-wrapper {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.slide-3 {
	background-image: url('/20200317134300/assets/images/43016677730_f8eb0e4389_k.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-4 {
	background-image: url('/20200317134300/assets/images/Ford-2011-PowerStroke_B20-compatible.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.navbar-3 {
	position: fixed;
	top: 0%;
	width: 100%;
	display: block;
	padding-right: 10%;
	padding-left: 10%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	box-shadow: 0 1px 12px -4px #000;
}

.image-13 {
	width: 90px;
}

.brand-2 {
	margin-left: 0px;
	padding-top: 17px;
	padding-bottom: 10px;
}

.main-nav-ul {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 92px;
	margin-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 92px;
	margin-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-15 {
	width: 100%;
	margin-right: 0px;
}

.slide-right-arrow {
	display: block;
	font-size: 28px;
}

.slide-left-arrow {
	display: block;
	font-size: 28px;
}

.slider-button {
	margin-right: 50px;
	margin-bottom: 30px;
	margin-left: 40px;
	border-radius: 20px;
	background-color: #ffd100;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Muli, sans-serif;
	color: #005e9e;
	font-size: 15px;
	line-height: 26px;
	font-weight: 700;
}

.slider-button:hover {
	background-color: #fff;
	color: #005e9e;
}

.left-arrow {
	width: 53px;
}

.right-arrow {
	width: 53px;
}

.dropdown-toggle {
	height: 100%;
	padding: 24px 19px 24px 0px;
}

.dropdown-arrow {
	padding-left: 0px;
	color: #005e9e;
}

.dropdown-arrow:hover {
	color: #153604;
}

.dd-links-wrap a,
.dropdown-link {
	position: relative;
	vertical-align: top;
	text-decoration: none;
	padding: 10px 20px;
	text-align: left;
	margin: 0;
	white-space: nowrap;
	font-family: Muli, sans-serif;
	color: #fff;
	font-weight: 700;
	width: 100%;
	font-size: 0.875rem;
}

.dd-links-wrap a:hover,
.dropdown-link:hover {
	background-color: rgba(0, 0, 0, 0.11);
	color: #fff;
}

.dropdown-link.w--current {
	background-color: rgba(0, 0, 0, 0.23);
	color: #fff;
}

.dropdown-list {
	background-color: #005e9e;
}

.dropdown-list.w--open {
	background-color: #005e9e;
}

.image-14 {
	width: 100%;
	height: auto;
	max-height: 100%;
	max-width: none;
}

.div-block-16 {
	height: 45%;
	margin: 0px auto;
	width: 100%;
}

.div-block-17 {
	margin-top: 0px;
	margin-bottom: 0px;
	width: 100%;
}

.div-block-18 {
	margin-top: 0px;
	margin-bottom: 0px;
	width: 100%;
}

.div-block-19 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 60px;
	width: 40%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.twitter-feed {
	max-height: 750px;
	overflow: auto;
}

.slide-5 {
	background-image: url('/20200317134300/assets/images/44827922791_9b52531d18_k.jpg');
	background-position: 50% 41%;
	background-size: cover;
	background-repeat: no-repeat;
}

.h6-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #4a852c;
	font-size: 12px;
	line-height: 18px;
	text-decoration: underline;
	cursor: pointer;
}

.h6-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #b8b8b8;
	line-height: 6px;
}

.main-content-inner .breadcrumb-link {
	border-bottom: 1px solid #6bb745;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #005e9e;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	cursor: pointer;
}

.main-content-inner .breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #153604;
}

.breadcrumbs-list-item.breadcrumb-current,
.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	color: #153604;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.left-nav-link-3 {
	display: block;
	width: 218px;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	border-left: 2px none transparent;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #696b6d;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-3:hover {
	width: 218px;
	padding-right: 0rem;
	padding-left: 0.63rem;
	border-left-style: none;
	border-left-color: #e41356;
	background-color: transparent;
	color: #153604;
}

.left-nav-link-3.selected,
.left-nav-link-3.w--current {
	border-left-color: #c2d82e;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #005e9e;
	font-weight: 700;
	text-transform: none;
}

.left-nav-link-3.selected:hover,
.left-nav-link-3.w--current:hover {
	background-color: rgba(255, 255, 255, 0.47);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-3.greatgrandchild-link {
	width: 193px;
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	width: 192px;
	border-left-color: #e41356;
	box-shadow: none;
	color: #153604;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	border-left-color: #c2d82e;
	background-image: none;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #005e9e;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 400;
}

.left-nav-link-3.grandchild-link {
	width: 206px;
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	width: 206px;
	border-left-color: #e41356;
	background-color: transparent;
}

.left-nav-link-3.grandchild-link.w--current {
	border-left-color: #c2d82e;
	border-radius: 0px;
	background-image: none;
	color: #005e9e;
	font-weight: 700;
}

.left-nav-link-3.grandchild-link.w--current:hover {
	background-color: rgba(255, 255, 255, 0.47);
}

.text-link {
	border-bottom: 1px solid #005e9e;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #005e9e;
	line-height: 20px;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #005e9e;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.h4-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #4a852c;
	font-size: 18px;
	line-height: 24px;
	text-decoration: underline;
	cursor: pointer;
}

.h4-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #b8b8b8;
	line-height: 6px;
}

.inside-content-sect {
	position: relative;
	display: block;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0vw;
	padding-left: 0vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	background-image: url('/20200317134300/assets/images/Soy-Texture.svg');
	background-position: 50% 100%;
	background-size: contain;
	background-repeat: repeat-x;
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.main-content-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}



.ordered-list {
	padding-left: 20px;
	line-height: 22px;
}

.main-content-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 2rem;
	width: 100%;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	font-family: Muli, sans-serif;
}

.left-nav-wrapper {
	height: auto;
	margin-top: -3px;
	margin-right: 1rem;
	margin-left: 0rem;
	padding-right: 1rem;
	padding-left: 0.5rem;
	border-right: 1px none rgba(228, 19, 86, 0.59);
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.intro_paragraph,
.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #424242;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

blockquote,
.block-quote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-width: 9px;
	border-left-color: #6bb745;
	background-color: #e7e7e7;
	color: #404040;
	font-size: 1.35rem;
	line-height: 2rem;
}

.main-content-inner blockquote > * {
	font-size: 1.35rem;
	line-height: 2rem;
}


blockquote > *:last-of-type {
	margin-bottom: 0;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: none;
	min-height: auto;
	min-width: auto;
	margin-top: 0px;
	padding-top: 1rem;
	padding-right: 0.75px;
	padding-bottom: 1rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 3px none #e41356;
	background-color: hsla(0, 0%, 90.7%, 0.49);
	font-family: Muli, sans-serif;
}

.h3-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #4a852c;
	font-size: 24px;
	line-height: 30px;
	text-decoration: underline;
	cursor: pointer;
}

.h3-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #b8b8b8;
	line-height: 6px;
	text-decoration: underline;
}

.content-outer-wrap {
	position: relative;
	z-index: 2;
	display: block;
	height: auto;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 4rem;
	border-top: 8px none #629edc;
	font-family: Muli, sans-serif;
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-section-title-link {
	display: block;
	margin-bottom: 5px;
	padding: 0.25rem 0px 0.4rem 10px;
	border: 1px none #000;
	background-color: transparent;
	font-family: Muli, sans-serif;
	color: #005e9e;
	font-size: 1.7rem;
	line-height: 23px;
	font-weight: 700;
	text-decoration: none;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #4f95d0;
	font-size: 1rem;
}

.h5-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #4a852c;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;
	cursor: pointer;
}

.h5-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #2f6ca0;
	line-height: 6px;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
	color: #fff;
}

.container-3 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.inside-page-container {
	max-width: 1200px;
}

.page-banner {
	height: 400px;
	background-image: url('/20200317134300/assets/images/48124317413_aa69656390_k.jpg');
	background-position: 0% 0%;
	background-size: cover;
	background-repeat: no-repeat;
}



.right-sidebar .box .content,
.inside-page-sidebar-column-section-wrap {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	margin-left: 0px;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid hsla(0, 0%, 72%, 0.36);
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	font-size: 0.875rem;
	line-height: 1.375em;
}

.right-sidebar .box .content h4,
.inside-page-right-column-heading {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #005e9e;
	font-size: 1.5em;
	line-height: 1.2;
}

.inside-page-right-column-list {
	margin-top: 0rem;
}

.inside-page-right-column-list-item {
	margin-bottom: 0.25em;
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
}

.link {
	display: inline-block;
	color: #d32329;
	text-decoration: underline;
}

.link:hover {
	color: #610609;
}

.right-sidebar .box .content a,
.link.inside-right-column-link {
	padding-right: 0.125em;
	padding-left: 0.125em;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #4a852c;
	border-bottom: 0;
	text-decoration: underline;
}

.right-sidebar .box .content a:hover,
.link.inside-right-column-link:hover {
	color: #4a852c;
	text-decoration: none;
}

.div-block-20 {
	width: 350px;
	margin-left: 1rem;
	font-family: Muli, sans-serif;
}

.link-2 {
	color: #fff;
	text-decoration: none;
}

.link-3 {
	text-decoration: none;
}

/* GRIP logo */
span#GRIPFooterLogoText {
	line-height: 1;
}

div#GRIPFooterLogo {
	padding-top: 0 !important;
}

/* Phone links */
.phone-mobile {
	display: none;
}

@media (max-width: 991px) {
	.phone-mobile {
		display: inline-block;
	}

	.phone-desktop {
		display: none;
	}
}

/* main navigation */
.main-nav-ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.dropdown-toggle {
	display: block;
	height: 0;
	opacity: 0;
	padding: 0;
	-webkit-transition: opacity 300ms ease 0s; /* Safari */
	transition: opacity 300ms ease 0s;
}

.open .dropdown-toggle {
	height: auto;
	opacity: 1;
	width: 100%;
}

.dropdown-list {
	height: inherit;
	overflow: hidden;
	display: block;
	padding: 0;
	list-style-type: none;
	top: 23px;
	left: 0;
}

.dd-links-wrap {
	display: flex;
}

.main-nav-li {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.main-nav-li:last-of-type .dropdown-list {
	right: 0;
	left: auto;
}

.main-nav-li.hasChildren .nav-button:after {
	content: "\e603";
	font-family: 'webflow-icons' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* homepage content boxes */
/* news */
.content-boxes .cb-container #news_module > a {
	border-bottom: 1px solid #005e9e;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #005e9e;
	font-weight: 500;
	text-decoration: none;
	display: none;
}

.content-boxes .cb-container #news_module > a:hover {
	border-bottom-color: transparent;
	box-shadow: none;
	color: #005e9e;
}

.content-boxes .cb-container #news_module h3 {
	font-size: 1.1rem;
	line-height: 1.2;
	margin: 0 0 5px 0;
}

.content-boxes .cb-container #news_module h3 a {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #427728;
	text-decoration: underline;
	cursor: pointer;
}

.content-boxes .cb-container #news_module h3 a:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #b8b8b8;
	line-height: 6px;
	text-decoration: underline;
}

.content-boxes .cb-container #news_module .cms_metadata2.cms_date h3 {
	font-size: 1rem;
	color: #000;
}

#news_module .cms_content {
	display: flex;
	flex-direction: column;
}

#news_module .cms_content .image {
	order: 100;
}

#news_module.cms_entity .cms_content .image {
	margin: 0px 0px 20px;
}

/* inside page */
/* left */
.main-content.with-left-sidebar {
	width: 80%;
	padding-left: 3vw;
}

/* right */
.main-content.with-right-sidebar {
	display: flex;
}

.main-content.with-right-sidebar .main-content-inner {
	width: 75%;
}

.main-content.with-right-sidebar .div-block-20.right-sidebar {
	width: 25%;
	padding-left: 5%;
}

/* both */
.main-content.both-sidebars {
	width: 80%;
	padding-left: 3vw;
	display: flex;
}

.main-content.both-sidebars .main-content-inner {
	width: 75%;
}

.main-content.both-sidebars .div-block-20 {
	width: 25%;
	padding-left: 5%;
	margin: 0;
}

.footer-green-btn {
	margin-top: 15px;
}

@media (max-width: 991px) {
	/* left */
	.main-content.with-left-sidebar {
		width: 100%;
		padding-left: 0;
	}

	/* right */
	.main-content.with-right-sidebar {
		flex-direction: column;
	}

	.main-content.with-right-sidebar .main-content-inner {
		width: 100%;
	}

	.main-content.with-right-sidebar .div-block-20.right-sidebar {
		width: 100%;
	}

	/* both */
	.main-content.both-sidebars {
		width: 100%;
		flex-direction: column;
		padding-left: 0;
	}

	.main-content.both-sidebars .main-content-inner {
		width: 100%;
	}

	.main-content.both-sidebars .div-block-20 {
		width: 100%;
		margin-top: 3rem;
	}
}

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-page__blocker {
		z-index: 1001;
	}

	.mm-menu {
		background: #fff;
		height: 100vh;
		position: absolute;
	}

	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		max-width: none;
		position: fixed;
	}

	.w-nav-link {
		font-family: Muli, sans-serif;
		font-weight: 800;
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25em 1.375rem;
		float: none;
		box-shadow: none;
		border-bottom: 1px solid #0003;
		text-transform: uppercase;
	}

	.mm-menu .mm-listitem.active > a:not(.mm-btn_next),
	.mm-menu .mm-listitem.active > span,
	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: transparent;
		cursor: pointer;
		color: #153604;
	}

	.mm-menu a.w--current {
		background-color: transparent;
		color: #153604;
		cursor: pointer;
	}

	.mm-menu a:hover {
		background-color: transparent;
		color: #153604;
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: #0003;
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #fff;
		-webkit-transition: border-color 300ms;
		transition: border-color 300ms;
	}

	.mm-navbar:hover .mm-btn_prev:before,
	.mm-menu .mm-btn:hover:after,
	.mm-menu .mm-btn:hover:before,
	.mm-menu .mm-listview .mm-btn_next:hover:after {
		border-color: #153604;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #153604;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: transparent;
		color: #fff;
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid #0003;
		padding: 1px 10px 0 40px;
		height: 61px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		transition: color 300ms;
		height: 61px;
		border-bottom: 0 none !important;
	}

	.mm-menu a.mm-navbar__title {
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
		font-family: Muli, sans-serif;
		text-transform: uppercase;
		font-weight: 800;
	}

	.mm-navbar:hover {
		cursor: pointer;
		background-color: transparent;
	}
}
/* end of mmenu */

/* session messages */
#session-message-wrapper .success {
	background-color: #adebb0;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .error {
	background-color: #f5dadd;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	font-weight: 400;
	font-size: 1.125rem;
	color: #333;
}

.success :last-child,
.error :last-child {
	margin-bottom: 0;
}
/* end of session messages */

/* Overall Table Characteristics */

.styled {
	width: 100%;
	margin: 0 0 0.75rem 0;
	background-color: white;
	border-spacing: 0px;
}
/* Vertical Cell Borders */

.styled th,
.styled td {
	border-right: 1px solid #2e2e2e;
}

.styled tr th:last-child,
.styled tr td:last-child {
	border-right: none;
}
/* Table Header - aka Table Title */

.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #6bb745;
	color: #000;
}
/* Optional Column Headers */

.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #005e9e;
	color: #ffffff;
}
/* This code controls any visual distinctions for link decoration */

.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border: 0;
}
/* Depending on your design, you may want this for the link hover state */

.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}
/* Overall Data Characteristics */

.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}
/* Even Row Data */

.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}
/* Odd Row Data */

.styled.striped tr:nth-child(odd) {
	background: transparent;
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	color: #333;
	border: 1px solid #ccc;
}

textarea {
	resize: vertical;
}
/* end of forms */

/* Phone links */
.phone-mobile {
	display: none;
}

@media (max-width: 991px) {
	.phone-mobile {
		display: inline-block;
	}

	.phone-desktop {
		display: none;
	}
}
/* end of Phone links */

.twitter-timeline {
	color: #005e9e;
	background-color: #fff;
}



/* media queries */
@media (max-width: 1316px) {
	.page-banner {
		background-position: 50% 80px;
	}

	.copyright-text {
		margin-bottom: 1rem;
		margin-right: 0;
		font-size: 13px;
	}

	.footer-signoff-list-item {
		padding-top: 0;
	}

	.row.footer-signoff-row {
		flex-direction: column;
	}

	.column.footer-signoff-column {
		flex-direction: column;
		margin-bottom: 1rem;
	}

	.footer-section {
		padding: 40px 100px 40px 100px;
	}
}

@media screen and (max-width: 991px) {
	.heading-16 {
		font-size: 2.8rem;
	}

	h1 {
		font-size: 2.2rem;
	}

	h2 {
		font-size: 1.9rem;
	}

	h3 {
		font-size: 1.4rem;
	}

	.slider {
		height: 400px;
	}

	.slide {
		background-position: 50% 100%;
	}

	.static-image {
		background-image: url('/20200317134300/assets/images/44107268054_3878f85932_k.jpg');
		background-position: 0px 0px, 50% 50%;
	}

	.main-nav-link,
	.main-nav-link,
	.nav-button {
		border-bottom-style: none;
		color: #fff;
		font-size: 20px;
		line-height: 29px;
	}

	.main-nav-link:hover,
	.nav-button:hover {
		color: #153604;
	}

	.slide-2 {
		background-position: 100% 0%;
		background-size: 180%;
	}

	.slider-image {
		height: 400px;
		background-position: 100% 8%;
		background-size: 90%;
	}

	.content-boxes {
		height: auto;
		padding: 60px 40px;
	}

	.video-section {
		height: auto;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.slider-callout {
		width: 55%;
		font-size: 30px;
		line-height: 32px;
	}

	.cb-container {
		width: 80%;
		height: auto;
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.latest-news-img {
		width: 100%;
		height: auto;
		min-height: auto;
	}

	.projects-img {
		width: 100%;
	}

	.biodiesel-in-iowa-img {
		width: 100%;
	}

	.static-callout {
		width: 60%;
		line-height: 51px;
		text-align: center;
	}

	.callout-text {
		width: 60%;
		line-height: 25px;
	}

	.footer-section {
		padding-right: 80px;
		padding-left: 80px;
	}

	.paragraph-5 {
		font-size: 12px;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.footer-social-block {
		padding-right: 1rem;
		text-align: left;
	}

	.footer-flex-row {
		padding-right: 20px;
		padding-left: 20px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-contact-wrap {
		width: 33%;
		padding-right: 1rem;
	}

	.footer-link {
		font-size: 1.1em;
	}

	.footer-column-title {
		font-size: 1.2em;
	}

	.footer-social-icons-wrap {
		margin-left: 0px;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column {
		margin-bottom: 1.5em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		font-size: 12px;
	}

	.column.footer-signoff-column.right-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.twitter-feed {
		width: 100%;
		height: 100%;
		margin-top: 40px;
		margin-bottom: 60px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.video-div {
		width: 33%;
		height: auto;
		padding-right: 0px;
	}

	.video-div-copy {
		width: 80%;
		height: auto;
		margin: 0px 80px;
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.video-place-holder {
		width: 100%;
		margin-right: 0px;
	}

	.video-title {
		width: 100%;
		margin-top: 0px;
		margin-right: 0px;
		margin-left: 0px;
		font-size: 25px;
	}

	.video-caption {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 20px;
		margin-left: 0px;
		font-size: 15px;
		line-height: 24px;
	}

	.navbar-3 {
		padding-right: 60px;
		padding-left: 60px;
	}

	.brand-2 {
		margin-left: 20px;
	}

	.nav-menu {
		background-color: #55a639;
	}

	.div-block-15 {
		width: 100%;
		height: auto;
		margin: 40px 0px 10px;
	}

	.menu-button {
		height: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
		background-color: #fff;
		color: #b64a4a;
	}

	.menu-button:active {
		background-color: #fff;
		color: #fff;
	}

	.slide-right-arrow {
		font-size: 25px;
	}

	.slide-left-arrow {
		font-size: 25px;
	}

	.slider-button {
		font-size: 14px;
		line-height: 22px;
	}

	.hamburger-icon {
		margin-top: 0px;
		color: #005e9e;
		font-size: 36px;
	}

	.left-arrow {
		width: 43px;
	}

	.right-arrow {
		width: 43px;
	}

	.dropdown-toggle {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.dropdown-toggle.w--open {
		padding-bottom: 0px;
	}

	.dropdown-arrow {
		margin-right: 60px;
		padding-left: 0px;
		color: #fff;
	}

	.dropdown-link {
		color: #fff;
	}

	.dropdown-link:hover {
		color: #153604;
	}

	.dropdown-list.w--open {
		padding-left: 20px;
		background-color: #55a639;
	}

	.image-14 {
		width: 100%;
		height: auto;
		max-height: none;
		margin-bottom: 0px;
	}

	.div-block-16 {
		margin-top: 0px;
	}

	.div-block-17 {
		width: 100%;
		margin-top: 0px;
	}

	.div-block-18 {
		width: 100%;
		margin-top: 0px;
	}

	.div-block-19 {
		width: 80%;
		margin: 0;
	}

	.slide-5 {
		background-position: 50% 100%;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
	}

	.intro_paragraph,
	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.left-nav-col {
		display: none;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.container-3 {
		max-width: 768px;
	}

	.right-sidebar .box .content,
	.inside-page-sidebar-column-section-wrap {
		margin-left: 0px;
	}

	.copyright-text h1 {
		font-size: 13px;
	}

	.footer-quick-link {
		font-size: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.heading-16 {
		font-size: 2.5rem;
	}

	h1 {
		font-size: 2.1rem;
	}

	h2 {
		font-size: 1.8rem;
	}

	h3 {
		font-size: 1.3rem;
	}

	.slider {
		height: 300px;
	}

	.slider-section {
		margin-top: 70px;
	}

	.slider-image {
		height: 300px;
		background-position: 100% 100%;
	}

	.content-boxes {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.slider-callout {
		width: 54%;
		margin-right: 30px;
		margin-bottom: 10px;
		font-size: 20px;
		line-height: 27px;
	}

	.cb-container {
		width: 95%;
		margin-top: 40px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.static-callout {
		width: 80%;
	}

	.callout-text {
		width: 80%;
	}

	.footer-section {
		padding-right: 40px;
		padding-left: 40px;
	}

	.paragraph-5 {
		font-size: 12px;
	}

	.footer-column-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0rem;
		padding-top: 0.5rem;
		padding-right: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.footer-social-block {
		padding-left: 0rem;
	}

	.footer-flex-row {
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-contact-wrap {
		width: 40%;
		padding-right: 1rem;
	}

	.footer-column-title {
		font-size: 1.1em;
	}

	.footer-social-icons-wrap {
		margin-bottom: 0em;
		padding-right: 10px;
	}

	.footer-signoff-list {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.copyright-text {
		width: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
	}

	.column.footer-signoff-column {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.column.footer-signoff-column.right-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-list-item {
		font-size: 6px;
	}

	.twitter-feed {
		width: auto;
		margin-right: 20px;
		margin-left: 20px;
	}

	.video-div-copy {
		margin: 0;
	}

	.navbar-3 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.image-13 {
		width: 88px;
	}

	.brand-2 {
		height: 100%;
	}

	.menu-button {
		margin-top: 10px;
	}

	.menu-button.w--open {
		margin-top: 10px;
	}

	.slide-right-arrow {
		font-size: 20px;
	}

	.slide-left-arrow {
		font-size: 20px;
	}

	.slider-button {
		margin-right: 30px;
		line-height: 22px;
	}

	.hamburger-icon {
		font-size: 27px;
	}

	.left-arrow {
		width: 29px;
	}

	.right-arrow {
		width: 29px;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content-inner-wrap {
		padding-top: 0rem;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.container-3 {
		max-width: 550px;
	}

	.page-banner {
		height: 300px;
	}

	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.footer-quick-link {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 479px) {
	.heading-16 {
		font-size: 2.225rem;
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.7rem;
	}

	h3 {
		font-size: 1.2rem;
	}

	.slider {
		height: 400px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#55a639), to(#55a639));
		background-image: linear-gradient(180deg, #55a639, #55a639);
	}

	.slide {
		background-image: url('/20200317134300/assets/images/27656263908_fc1a4f8d7f_k.jpg'), -webkit-gradient(linear, left top, left bottom, from(#6bb745), to(#6bb745));
		background-image: url('/20200317134300/assets/images/27656263908_fc1a4f8d7f_k.jpg'), linear-gradient(180deg, #6bb745, #6bb745);
		background-position: 50% 0%, 0px 0px;
		background-size: auto 100%, auto;
		background-repeat: no-repeat, repeat;
	}

	.static-image {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.32)), to(rgba(0, 0, 0, 0.32))), url('/20200317134300/assets/images/44107268054_3878f85932_k.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), url('/20200317134300/assets/images/44107268054_3878f85932_k.jpg');
		background-position: 0px 0px, 59% 50%;
	}

	.main-nav-link,
	.nav-button {
		font-size: 17px;
	}

	.slide-2 {
		background-image: url('/20200317134300/assets/images/37588589676_4c5506f990_k.jpg'), -webkit-gradient(linear, left top, left bottom, from(#55a639), to(#55a639));
		background-image: url('/20200317134300/assets/images/37588589676_4c5506f990_k.jpg'), linear-gradient(180deg, #55a639, #55a639);
		background-position: 50% 0%, 0px 0px;
		background-size: auto 100%, auto;
		background-repeat: no-repeat, repeat;
	}

	.slider-image {
		height: 400px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		background-image: url('/20200317134300/assets/images/Slider-Graphic-V2.svg');
		background-position: 100% 100%;
		background-size: 125%;
		background-repeat: no-repeat;
	}

	.content-boxes {
		padding: 40px 20px;
	}

	.blue-title {
		margin: 10px 10px 0px;
		padding-left: 10px;
		font-size: 20px;
		line-height: 26px;
	}

	.cb-description {
		margin-right: 10px;
		margin-left: 10px;
		padding-left: 10px;
		font-size: 15px;
		line-height: 22px;
	}

	.cb-button {
		margin-left: 20px;
	}

	.slider-callout {
		width: 81%;
		margin-right: 20px;
		margin-bottom: 10px;
		font-size: 15px;
		line-height: 21px;
		text-align: right;
	}

	.cb-container {
		height: auto;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.latest-news-img {
		height: 51%;
	}

	.projects-img {
		height: 51%;
	}

	.biodiesel-in-iowa-img {
		height: 51%;
	}

	.static-callout {
		width: 70%;
		margin-bottom: 10px;
		font-size: 30px;
		line-height: 36px;
	}

	.callout-text {
		width: 70%;
		font-size: 15px;
		line-height: 20px;
	}

	.footer-section {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.footer-social-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1rem;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.footer-flex-row {
		margin-right: 10px;
		margin-left: 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-contact-wrap {
		width: 100%;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.footer-link {
		font-size: 1rem;
	}

	.footer-social-icons-wrap {
		margin-left: 10px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-signoff-list {
		text-align: center;
	}

	.copyright-text {
		font-size: 0.8rem;
	}

	.row.footer-signoff-row {
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column.footer-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-list-item {
		padding-right: 0.35rem;
		padding-left: 0.35rem;
	}

	.twitter-feed {
		width: 100%;
		height: auto;
		margin-left: 0px;
		margin-right: 0;
	}

	.video-div-copy {
		margin-right: 0;
		margin-left: 0px;
	}

	.video-place-holder {
		margin-top: 10px;
		margin-bottom: 40px;
	}

	.slide-nav {
		margin-bottom: -3px;
		font-size: 10px;
	}

	.video-caption {
		margin-bottom: 0px;
	}

	.slide-3 {
		background-position: 50% 0%;
		background-size: auto 100%;
	}

	.slide-4 {
		background-position: 50% 50%;
		background-size: auto 100%;
	}

	.navbar-3 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.div-block-15 {
		margin-top: 40px;
	}

	.menu-button {
		margin-right: 15px;
	}

	.slide-right-arrow {
		font-size: 20px;
	}

	.slide-left-arrow {
		font-size: 20px;
	}

	.slider-button {
		margin-right: 20px;
		margin-bottom: 25px;
		font-size: 12px;
		line-height: 19px;
	}

	.hamburger-icon {
		font-size: 29px;
	}

	.left-arrow {
		width: 27px;
	}

	.right-arrow {
		width: 27px;
	}

	.slide-5 {
		background-image: url('/20200317134300/assets/images/44827922791_9b52531d18_k.jpg'), -webkit-gradient(linear, left top, left bottom, from(#6bb745), to(#6bb745));
		background-image: url('/20200317134300/assets/images/44827922791_9b52531d18_k.jpg'), linear-gradient(180deg, #6bb745, #6bb745);
		background-position: 50% 0%, 0px 0px;
		background-size: auto 100%, auto;
		background-repeat: no-repeat, repeat;
	}

	.inside-content-sect {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.intro_paragraph,
	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.content-outer-wrap {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.container-3 {
		max-width: none;
	}

	.footer-link.signoff-link {
		font-size: 0.7rem;
	}
}

/* ie11 fixes */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.slider-image {
		background-position: 1500% 100%;
		background-size: 98%;
	}
}
